@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
@font-face {
  font-family: Baunk;
  src: url("/static/fonts/Baunk.ttf");
}
@font-face {
  font-family: Vegawanty;
  src: url("/static/fonts/Vegawanty.ttf");
}
@font-face {
  font-family: Wagon;
  src: url("/static/fonts/Wagon-Bold.otf");
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: Montserrat;
}

html,
body {
  overflow: hidden;
  background-color: black;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

h1, h2, h3, h4 {
  color: white;
  font-family: Baunk;
}
h1 span, h2 span, h3 span, h4 span {
  color: white;
  font-family: Baunk;
}

p {
  color: white;
  line-height: 1.5em;
  margin: 20px 0;
}

.planet {
  position: absolute;
  top: 50%;
  left: 50%;
}
.planet .label {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
  transform: scale(0);
  transition: all 0.2s;
  z-index: 9;
  cursor: pointer;
}
.planet .label::after {
  content: "";
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
}
.planet .label:hover {
  outline-offset: 10px;
}
.planet .label:hover::after {
  width: 30px;
  height: 30px;
}
.planet .hud {
  width: 500px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: all 0.2s;
  opacity: 0;
  pointer-events: none;
  z-index: 99999;
}
.planet .hud .cls-1 {
  fill: none;
}
.planet .hud .cls-2 {
  clip-path: url(#clippath);
}
.planet .hud .cls-3, .planet .hud .cls-4 {
  fill: #fff;
}
.planet .hud .cls-5 {
  fill: rgba(0, 0, 0, 0.6);
}
.planet .hud .cls-4, .planet .hud .cls-4 tspan {
  font-family: Baunk;
  font-size: 2em;
}
.planet .content {
  position: absolute;
  top: 30px;
  left: -120px;
  max-width: 40vw;
  padding: 20px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid white;
  border-radius: 10px;
  color: #ffffff;
  line-height: 1.3em;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 14px;
  opacity: 0;
  transition: all 0.2s;
  pointer-events: none;
  text-align: center;
  z-index: 99;
}
.planet .content h3 {
  margin-bottom: 10px;
}
.planet .content p {
  margin: 0;
}
.planet .content img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}
.planet.visible .label {
  transform: scale(1);
}
.planet:hover .content, .planet:hover .hud {
  opacity: 1;
}

header {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 20vw;
  height: 15vh;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
header .logo {
  height: 100%;
}
header .logo svg {
  height: 80%;
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  padding: 20px 5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}
footer h1 {
  font-size: 1em;
  user-select: none;
}
footer .links {
  height: 100%;
}
footer .links a {
  height: 80%;
}
footer .links a:not(:last-of-type) {
  margin-right: 30px;
}
footer .links a svg {
  fill: white;
  height: 100%;
  transition: all 0.2s;
}
footer .links a svg path {
  stroke: white;
  stroke-width: 0;
  transition: all 0.2s;
}
footer .links a svg:hover {
  fill: transparent;
  transition: all 0.1s;
}
footer .links a svg:hover path {
  stroke: white;
  stroke-width: 1px;
  transition: all 0.1s;
}

.detailsModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-color: #0f0f0f;
  z-index: 5;
  transform: translateY(100%);
  transition: all 1s ease-in-out;
}
.detailsModal.visible {
  transform: translateY(0);
}
.detailsModal .header {
  height: 15vh;
  width: 100%;
  padding: 20px 5vw;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #0f0f0f;
  position: sticky;
  top: 0;
  z-index: 5;
}
.detailsModal .header .backArrow {
  height: 100%;
  justify-self: left;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: translate 0.2s linear;
}
.detailsModal .header .backArrow svg {
  height: 60%;
  fill: white;
}
.detailsModal .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 50px;
}
.detailsModal .content h2 {
  margin: 20px 0 3vw 0;
  font-size: 2em;
  overflow: hidden;
}
.detailsModal .content h2 span {
  display: inline-block;
}
.detailsModal .content .wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
}
.detailsModal .content .wrapper .description {
  margin: 0 20px;
  width: 60%;
}
.detailsModal .content .wrapper .description p {
  overflow: hidden;
}
.detailsModal .content .wrapper .description p span:not(#age) {
  display: inline-block;
}
.detailsModal .content .wrapper .description .links {
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
}
.detailsModal .content .wrapper .description a {
  display: inline-block;
  text-align: center;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  transition: all 0.2s;
}
.detailsModal .content .wrapper .description a:hover {
  background-color: white;
  border: 1px solid white;
  color: black;
}
.detailsModal .content .wrapper .images {
  width: 40%;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.detailsModal .content .wrapper .images img {
  max-width: 40vw;
  max-height: 60vh;
  border-radius: 10px;
  overflow: hidden;
  object-fit: cover;
  margin-bottom: 3vw;
  opacity: 0;
}

/***** MOBILE PORTRAIT *****/
@media screen and (max-width: 480px) {
  header {
    height: 10vh;
  }

  .planet .content {
    display: none;
  }

  footer h1 {
    font-size: 0.8em;
  }
  footer .links {
    height: 60%;
  }

  .detailsModal {
    padding-bottom: 10vh;
  }
  .detailsModal.visible {
    transform: translateY(0);
  }
  .detailsModal .header {
    height: 10vh;
  }
  .detailsModal .content h2 {
    font-size: 1.2em;
  }
  .detailsModal .content .wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .detailsModal .content .wrapper .description {
    width: 80%;
    text-align: center;
  }
  .detailsModal .content .wrapper .description a {
    max-width: 40%;
  }
  .detailsModal .content .wrapper .images {
    width: 80%;
    margin-top: 5vh;
  }
  .detailsModal .content .wrapper .images img {
    max-width: 80vw;
    max-height: 60vh;
  }
}
/***** MOBILE PAYSAGE *****/
@media screen and (min-width: 480px) and (max-width: 991px) and (orientation: landscape) {
  header {
    height: 20vh;
  }

  .planet .content {
    display: none;
  }

  footer {
    padding: 10px 5vw;
    height: 15vh;
  }
  footer h1 {
    font-size: 0.8em;
  }
  footer .links {
    height: 60%;
  }

  .detailsModal.visible {
    transform: translateY(0);
  }
  .detailsModal .header {
    height: 20vh;
  }
  .detailsModal .content h2 {
    margin: 5px 0 3vw 0;
    font-size: 1.2em;
  }
  .detailsModal .content .wrapper .description {
    width: 80%;
    text-align: center;
  }
  .detailsModal .content .wrapper .description a {
    max-width: 40%;
  }
  .detailsModal .content .wrapper .images {
    width: 80%;
    margin-top: 5vh;
  }
  .detailsModal .content .wrapper .images img {
    max-width: 80vw;
    max-height: 60vh;
  }
}
/***** TABLETTE PORTRAIT *****/
@media screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
  header {
    height: 10vh;
  }

  .planet .content {
    display: none;
  }

  footer h1 {
    font-size: 1.2em;
  }
  footer .links {
    height: 60%;
  }

  .detailsModal.visible {
    transform: translateY(0);
  }
  .detailsModal .header {
    height: 10vh;
  }
  .detailsModal .content h2 {
    font-size: 1.2em;
  }
  .detailsModal .content .wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .detailsModal .content .wrapper .description {
    width: 80%;
    text-align: center;
  }
  .detailsModal .content .wrapper .description a {
    max-width: 40%;
  }
  .detailsModal .content .wrapper .images {
    width: 80%;
    margin-top: 5vh;
  }
  .detailsModal .content .wrapper .images img {
    max-width: 80vw;
    max-height: 60vh;
  }
}
/***** TABLETTE PAYSAGE *****/
@media screen and (min-width: 992px) and (max-width: 1199px) and (orientation: landscape) {
  .planet .content {
    display: none;
  }
}